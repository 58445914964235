<template>
  <b-row no-gutters
    ><b-col xl="5" cols="4"><div class="gfg-icon"></div></b-col
    ><b-col class="d-flex flex-column align-items-center mb-xl-5 mb-3"
      ><span class="text-golden bold text-uppercase huge">{{
        $t("events.olympics.tutorial.page_1.headline")
      }}</span>
      <p class="text-center text-white medium large text-shadow">
        {{ $t("events.olympics.tutorial.page_1.subline") }}
      </p></b-col
    ><b-col cols="12" class="mb-3">
      <span class="text-uppercase green bold larger">{{
        $t("events.olympics.tutorial.page_1.explanation_heading")
      }}</span></b-col
    ><b-col cols="4" class="d-flex flex-column align-items-center"
      ><div class="cart mb-3"></div>
      <div class="circled-number mb-3">1</div>
      <span class="green text-uppercase bold large mb-1">{{
        $t("events.olympics.tutorial.page_1.step_1.heading")
      }}</span>
      <p class="text-white normalsize">
        {{ $t("events.olympics.tutorial.page_1.step_1.text") }}
      </p>
      <div class="arrow"></div></b-col
    ><b-col cols="4" class="d-flex flex-column align-items-center"
      ><div class="medals mb-3"></div>
      <div class="circled-number mb-3">2</div>
      <span class="green text-uppercase bold large mb-1">{{
        $t("events.olympics.tutorial.page_1.step_2.heading")
      }}</span>
      <p class="text-white normalsize">
        {{ $t("events.olympics.tutorial.page_1.step_2.text") }}
      </p>
      <div class="arrow"></div></b-col
    ><b-col cols="4" class="d-flex flex-column align-items-center"
      ><div class="coins mb-3"></div>
      <div class="circled-number mb-3">3</div>
      <span class="green text-uppercase bold large mb-1">{{
        $t("events.olympics.tutorial.page_1.step_3.heading")
      }}</span>
      <p class="text-white normalsize" v-html="step3Text"></p></b-col
  ></b-row>
</template>
<script>
export default {
  name: "ModalOlympiaTutorialPage1",
  computed: {
    step3Text() {
      return this.$t("events.olympics.tutorial.page_1.step_3.text");
    },
  },
};
</script>
<style scoped lang="scss">
.cart {
  width: 60px;
  height: 52px;
  background: url(~@/assets/img/events/gfg-basket-inactive.svg) center/100% 100%
    no-repeat;
}
.medals {
  width: 128px;
  height: 50px;
  background: url(~@/assets/img/events/gfg-medal-gold.png) left/36px 100%
      no-repeat,
    url(~@/assets/img/events/gfg-medal-silver.png) center/36px 100% no-repeat,
    url(~@/assets/img/events/gfg-medal-bronze.png) right/36px 100% no-repeat;
}
.coins {
  width: 87px;
  height: 54px;
  background: url(~@/assets/img/common/icn-coins-big.png) center/100% 100%
    no-repeat;
}

.arrow {
  position: absolute;
  width: 163px;
  height: 25px;
  right: -27%;
  top: 23%;
  background: url(~@/assets/img/events/arrow-line.svg) center/100% 100%
    no-repeat;
}
</style>
